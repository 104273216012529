import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/Layout"
import { getComicPages } from "../utils/utils"
import StyledFullCover from "../components/FullCover"
import StyledDivBox from "../components/DivBox"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import UseSiteMetadata from "../hooks/use-sitemeta"
import CoverImage from "../images/mechanical-bird-website-cover-4k-v3.svg"
import styled from 'styled-components'

const HeroContainer = styled.div`
    border-radius: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100vh;
    width: 100%;
    background: url(${props => props.img}) center center no-repeat;
    object-fit: contain;
`

export default function SiteIndex( { data } ) {
	const longformBooks = data.longformbooks.nodes;
	const shortformBooks = data.shortformbooks.nodes;
	const pages = data.comicpages.nodes;
    const { siteUrl, title } = UseSiteMetadata();
    const currAbsUrl = siteUrl + '/comics/';
  
    const blogTitle = title;
    const blogDesc = "A listing of all the available comics to read for " + title + ".";

	return (
		<Layout
            absUrl={currAbsUrl}
			title={blogTitle}
			description={blogDesc}
		>
            {/* <img src={CoverImage} alt="Cover Image" /> */}
            <HeroContainer img={CoverImage} />

            
			<Container>
                <div className="my-5">
  			        <h2 id="previews">Book Previews</h2>
                </div>
				<Row>
                    {longformBooks.map(book => (
                        <StyledFullCover
                            key={'longformbook_' + book.id}
                            bookId={book.id}
                            bShowFeaturedText={true}
                            bShowButton={true}
                            frontmatter={book.frontmatter}
                            html={book.html}
                            pages={getComicPages(book.parent.relativeDirectory, pages)}
                        />
                    ))}
				</Row>
			</Container>

			<Container>
                <div className="my-5">
  			        <h2>Artwork of The Mechanical Bird</h2>
                </div>
				<Row>
					{shortformBooks.map(book => (
                        <StyledDivBox
                            key={'shortstory_' + book.id}
                            bookId={book.id}
                            bShowFeaturedText={true}
                            bShowButton={true}
                            frontmatter={book.frontmatter}
                            html={book.html}
                            imageData={null}
                            link=""
                            pages={getComicPages(book.parent.relativeDirectory, pages)}
                        />
					))}
				</Row>
			</Container>
		</Layout>
	)
}

export const query = graphql`
  fragment MarkdownFrontmatter on MarkdownRemark {
    frontmatter {
      slug
      title
      date
      posttype
      tags
      firstPage
      featuredText
      purchaseLink
      categories
      chapterList
      chapter
      featuredImage {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          original {
            height
            width
          }
        }
        relativeDirectory
        sourceInstanceName
      }
      comicImage {
        childImageSharp {
          gatsbyImageData(formats: WEBP, placeholder: BLURRED)
          original {
            height
            width
          }
        }
      }
    }
    html
  }
  query IndexBookQuery {
    longformbooks: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/comics/" }
        frontmatter: {
          posttype: { eq: "comicbook" }
          tags: { eq: "long-form", ne: "featured" }
        }
      }
      sort: { fields: frontmatter___date, order: ASC }
    ) {
      nodes {
        ...MarkdownFrontmatter
        id
        html
        parent {
          ... on File {
            relativeDirectory
            sourceInstanceName
          }
        }
      }
    }
    shortformbooks: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/comics/" }
        frontmatter: {
          posttype: { eq: "comicbook" }
          tags: { eq: "short-form" }
        }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        ...MarkdownFrontmatter
        id
        html
        parent {
          ... on File {
            relativeDirectory
            sourceInstanceName
          }
        }
      }
    }
    comicpages: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/comics/" }
        frontmatter: { posttype: { eq: "comicpage" } }
      }
      sort: {
        fields: [frontmatter___comic, frontmatter___date]
        order: [ASC, ASC]
      }
    ) {
      nodes {
        frontmatter {
          slug
          title
          date
          posttype
          tags
          comic
          chapter
        }
        id
        parent {
          ... on File {
            relativeDirectory
            sourceInstanceName
          }
        }
      }
    }
  }
`
